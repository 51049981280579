import React from 'react';
import styled from 'styled-components';
import Config from '../../../data.json';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import * as Ant from 'antd';
import qs from 'query-string';
import * as Dictionary from '../../dictionary';
import AdminSelectProductNameWidget from '../../Generators/AdminResource/AdminSelectProductNameWidget';
import AdminSelectVariantGroupWidget from '../../Generators/AdminResource/AdminSelectVariantGroupWidget';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';
import RevSingleImageUploader from 'rev.sdk.js/Generic/CustomWidgets/RevSingleImageUploader';
import AdminSelectArticleWidget from '../../Generators/AdminResource/AdminSelectArticleWidget';
import spec from '../../../content/admin/event.json';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import DragonBall from './DRAGON_BALL';
import DragonBallStamp from './DRAGON_BALL_STAMP';
import NormalPoint from './NORMAL_POINT';
import {getOutlet} from 'reconnect.js';
import Morinaga from './MORINAGA';

const ProductsOutlet = getOutlet('products');
const ArticlesOutlet = getOutlet('articles');

const basicValue = {
  name: '',
  type: '',
  start_time: Date.now(),
  end_time: Date.now(),
  threshold: 100,
};

export default function AdminEvent(props) {
  const {location} = props;
  const params = qs.parse(location.search);
  const {action, id} = params;
  const [record, _setRecord] = React.useState({...basicValue});

  const setRecord = React.useCallback(
    (value) => _setRecord((prev) => ({...prev, ...value})),
    [],
  );

  const submit = async () => {
    AppActions.setLoading(true);
    let resp;
    try {
      if (!record.name) {
        throw new Error('名稱不可為空');
      }

      if (action === 'create') {
        resp = await JStorage.createDocument(spec.collection, record);
        Ant.message.success('活動創建成功');
        AppActions.navigate(`/admin/event?action=detail&id=${resp.id}`);
      } else {
        resp = await JStorage.updateDocument(
          spec.collection,
          {id: record.id},
          record,
        );
        Ant.message.success('活動更新成功');
      }
      await AppActions.addEventHistory({
        collection: spec.collection,
        eventName: record.name,
        content: record,
      });
    } catch (e) {
      console.warn('error', e);
      Ant.message.error('發生錯誤 ' + e.message);
    }
    AppActions.setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      if (id) {
        AppActions.setLoading(true);
        try {
          _setRecord(await JStorage.fetchOneDocument(spec.collection, {id}));
        } catch (e) {
          console.warn(e);
        }
        AppActions.setLoading(false);
      }
    })();
  }, [id]);

  React.useEffect(() => {
    async function fetchAllProducts() {
      try {
        let resp = await JStorage.fetchAllDocuments('product', {}, null, {
          name: 1,
          price: 1,
        });
        ProductsOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    async function fetchAllArticles() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'Article_Default',
          {},
          null,
          {
            title: 1,
          },
        );

        ArticlesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchAllProducts();
    fetchAllArticles();
  }, []);

  return (
    <Wrapper>
      <Row>
        <BackButton
          className="resource-back-button"
          onClick={() => AppActions.navigate(spec.backPath || spec.path)}>
          <ChevronLeft size={24} />
          <div className="back-btn-text">{spec.backTitle || '返回'}</div>
        </BackButton>
        {action === 'create' ? (
          <h1 style={{marginLeft: 10}}>{`創建${spec.name}`}</h1>
        ) : (
          <h1 style={{marginLeft: 10}}>{`${spec.name}詳情`}</h1>
        )}
      </Row>

      <h2>活動設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          活動名稱
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.name}
            onChange={(e) => {
              let value = e.target.value;
              setRecord({name: value});
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          開始時間
        </Ant.Col>
        <Ant.Col span={6}>
          <AdminDateTimeWidget
            value={record.start_time}
            onChange={(value) => setRecord({start_time: value})}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          結束時間
        </Ant.Col>
        <Ant.Col span={6}>
          <AdminDateTimeWidget
            value={record.end_time}
            onChange={(value) => setRecord({end_time: value})}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          活動類型
        </Ant.Col>
        <Ant.Col span={6}>{record.type}</Ant.Col>
        <Ant.Col span={2} className="label">
          活動編號(ID)
        </Ant.Col>
        <Ant.Col span={6}>{record.id}</Ant.Col>
        <Ant.Col span={2} className="label">
          適用商品
        </Ant.Col>
        <Ant.Col span={6}></Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          消費門檻
        </Ant.Col>
        <Ant.Col span={6}>{record.threshold}</Ant.Col>
        <Ant.Col span={2} className="label">
          活動圖片
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.image}
            onChange={(value) => setRecord({image: value})}
            schema={{}}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          文章說明
        </Ant.Col>
        <Ant.Col span={6} style={{flexWrap: 'wrap'}}>
          <AdminSelectArticleWidget
            value={record.article}
            onChange={(value) => setRecord({article: value})}
          />
        </Ant.Col>
      </Ant.Row>

      {/* this way to fit various event */}
      {record?.type === 'DRAGON_BALL' ? (
        <DragonBall record={record} setRecord={_setRecord} />
      ) : record?.type === 'DRAGON_BALL_STAMP' ? (
        <DragonBallStamp record={record} setRecord={_setRecord} />
      ) : record?.type === 'NORMAL_POINT' ? (
        <NormalPoint record={record} setRecord={_setRecord} />
      ) : record.type === 'MORINAGA_CODE' ? (
        <Morinaga record={record} setRecord={_setRecord} />
      ) : null}

      <Row style={{marginTop: 10}}>
        <div style={{flex: 1}} />
        <Ant.Button type="primary" onClick={submit}>
          確認
        </Ant.Button>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }

  .ant-row {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
  .ant-row + .ant-row {
    border-top: 0;
  }

  .ant-col {
    padding: 6px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .label {
    background-color: #ffe6a1;
  }

  .hint {
    color: #ccc;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
